var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 table mt-7",attrs:{"headers":_vm.headers,"items":_vm.items,"disableSort":"","loading":_vm.loading,"item-key":"id","show-expand":_vm.showExpand,"single-expand":""},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayOrganization(item))+" ")]}},{key:"item.devices",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDevicesCount(item))+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayUser(item))+" ")]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [(item.phoneNumber.length === 10)?_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.phoneNumber,'###-###-####')))]):_c('span',[_vm._v(_vm._s(item.phoneNumber))])]}},{key:"item.emergencyNumber",fn:function(ref){
var item = ref.item;
return [(item.emergencyNumber.length === 10)?_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.emergencyNumber,'###-###-####')))]):_c('span',[_vm._v(_vm._s(item.emergencyNumber))])]}},{key:"item.operatorNumber",fn:function(ref){
var item = ref.item;
return [(item.operatorNumber.length === 10)?_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.operatorNumber,'###-###-####')))]):_c('span',[_vm._v(_vm._s(item.operatorNumber))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayRole(item.role))+" ")]}},{key:"item.orgLogoLink",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.orgLogoLink,"aspect-ratio":1,"width":50}})]}},{key:"item.rtmpLink",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"rtmp-id-font"},[_vm._v(_vm._s(item.id))]),(_vm.isDeviceTable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.$emit('onCopy', item)}}},[_vm._v(_vm._s(_vm.copyIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Copy RTMP Link")])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","disabled":(item.role == _vm.role)}},[_c('v-icon',{attrs:{"color":"darkblue"},on:{"click":function($event){return _vm.$emit('onEdit', item)}}},[_vm._v(_vm._s(_vm.editIcon))])],1),_c('v-btn',{attrs:{"icon":"","disabled":(item.isDefault || item.role == _vm.role)}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('onDelete', item)}}},[_vm._v(_vm._s(_vm.deleteIcon))])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.expandedList),function(data){return _c('tr',{key:data.text},[_c('td',[_vm._v(_vm._s(data.text)+":")]),_c('td',[_vm._v(_vm._s(_vm.displayExpandedValue(item, data.prop)))])])}),0)]},proxy:true}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }