<template>
  <v-data-table :headers="headers" :items="items" disableSort
    class="elevation-1 table mt-7"
    :loading="loading"
    item-key="id"
    :show-expand="showExpand"
    single-expand>
    <template v-slot:item.organization="{ item }">
      {{ displayOrganization(item) }}
    </template>

    <template v-slot:item.devices="{ item }">
      {{ displayDevicesCount(item) }}
    </template>

    <template v-slot:item.owner="{ item }">
      {{ displayUser(item) }}
    </template>

    <template v-slot:item.phoneNumber="{ item }">
      <span v-if="item.phoneNumber.length === 10">{{ item.phoneNumber | VMask('###-###-####') }}</span>
      <span v-else>{{ item.phoneNumber }}</span>
    </template>

    <template v-slot:item.emergencyNumber="{ item }">
      <span v-if="item.emergencyNumber.length === 10">{{ item.emergencyNumber | VMask('###-###-####') }}</span>
      <span v-else>{{ item.emergencyNumber }}</span>
    </template>

    <template v-slot:item.operatorNumber="{ item }">
      <span v-if="item.operatorNumber.length === 10">{{ item.operatorNumber | VMask('###-###-####') }}</span>
      <span v-else>{{ item.operatorNumber }}</span>
    </template>

    <template v-slot:item.role="{ item }">
      {{ displayRole(item.role) }}
    </template>

    <template v-slot:item.orgLogoLink="{ item }">
      <v-img :src="item.orgLogoLink" :aspect-ratio="1" :width="50"/>
    </template>

    <template v-slot:item.rtmpLink="{ item }">
      <div>
        <span class="rtmp-id-font">{{ item.id }}</span>
        <v-tooltip top v-if="isDeviceTable">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="blue" @click="$emit('onCopy', item)">{{ copyIcon }}</v-icon>
            </v-btn>
          </template>
          <span>Copy RTMP Link</span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <div>
        <v-btn icon :disabled="(item.role == role)">
          <v-icon color="darkblue" @click="$emit('onEdit', item)">{{ editIcon }}</v-icon>
        </v-btn>
        <v-btn icon :disabled="(item.isDefault || item.role == role)">
          <v-icon color="error" @click="$emit('onDelete', item)">{{ deleteIcon }}</v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="data in expandedList"
                :key="data.text"
              >
                <td>{{ data.text }}:</td>
                <td>{{ displayExpandedValue(item, data.prop) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex';
import { mdiPencil, mdiDelete, mdiContentCopy } from '@mdi/js';

export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    isUserTable: {
      type: Boolean,
      default: () => false,
    },
    isDeviceTable: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showExpand: {
      type: Boolean,
      default: () => false,
    },
    expandedList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editIcon: mdiPencil,
    deleteIcon: mdiDelete,
    copyIcon: mdiContentCopy,
  }),
  computed: {
    ...mapState('auth', ['role']),
    ...mapState('devices', ['allDevices']),
    ...mapState('organizations', ['organizations']),
    ...mapState('users', ['ownerList']),
  },
  methods: {
    displayOrganization(item) {
      const organization = this.organizations.find((org) => org.id === item.organization);
      return organization?.name;
    },
    displayDevicesCount(item) {
      const devices = this.allDevices.filter((device) => device.owner === item.id);
      return devices.length;
    },
    displayUser(item) {
      const user = this.ownerList.find((usr) => usr.id === item.owner);
      return `${user?.firstName || ''} ${user?.lastName || ''}`;
    },
    displayExpandedValue(item, prop) {
      return item[prop];
    },
    displayRole(role) {
      const roles = {
        superAdmin: 'Super Admin',
        eyeStaffAdmin: 'Staff Admin',
        eyeStaff: 'Staff',
        orgAdmin: 'Admin',
        orgUser: 'User',
      };
      return roles[role];
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
  overflow-x: auto;
}
.rtmp-id-font {
  font-family: Consolas;
}
</style>
